import React, { useState } from "react"
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { Card } from "../card"
import { Container } from '../styles'
import { Autocomplete } from "@material-ui/lab"
import { Backdrop, CircularProgress, Snackbar, makeStyles } from "@material-ui/core"
import { Alert as MuiAlert } from "@material-ui/lab"

import { API } from "../../services/api"

import { testID } from "../../constants/testId"
import LogRocket from 'logrocket'

import dotenv from 'dotenv'

dotenv.config()

const noOptions = "Nenhuma Opção"
const loadingText = "Carregando..."

const { REACT_APP_HUNTER_KEY, REACT_APP_SERVICE_KEY, REACT_APP_DESCRIPTION_KEY } = process.env

const hunterFieldKey = REACT_APP_HUNTER_KEY || ''
const serviceFieldKey = REACT_APP_SERVICE_KEY || ''
const descriptionFieldKey = REACT_APP_DESCRIPTION_KEY || ''

interface FormProps {
  hunters: any
  services: any
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const Form: React.FC<FormProps> = ({ hunters, services }) => {
  const classes = useStyles()
  const [organizations, setOrganizations] = useState<any[]>([])
  const [persons, setPersons] = useState<any[]>([])
  const [loading, setLoading] = useState<any>(false)

  const [hunterError, setHunterError] = useState<any>(false)
  const [serviceError, setServiceError] = useState<any>(false)
  const [organizationError, setOrganizationError] = useState<any>(false)
  const [personError, setPersonError] = useState<any>(false)
  const [personPhoneError, setPersonPhoneError] = useState<any>(false)
  const [personMailError, setPersonMailError] = useState<any>(false)
  const [titleError, setTitleError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)

  const [hunterSelected, setHuntersSelected] = useState<any>(null)
  const [serviceSelected, setServiceSelected] = useState<any>(null)

  const [personSelected, setPersonsSelected] = useState<any>(null)
  const [organizationSelected, setOrganizationSelected] = useState<any>(null)

  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")

  const [newPerson, setNewPerson] = useState<string>("")
  const [newPersonPhone, setNewPersonPhone] = useState<string>("")
  const [newPersonMail, setNewPersonMail] = useState<string>("")

  const [newOrg, setNewOrg] = useState<string>("")

  const [snack, setSnack] = useState(false)
  const [snackError, setSnackError] = useState(false)
  const [open, setOpen] = useState(false)

  let timer: any

  const handleClose = (event: any, reason?:any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
    setSnack(false)
    setSnackError(false)
  };

  const handlePersonSearch = (text: string) => {
    if (!text) return
    setNewPerson(text)
    if (text.length < 2) return
    clearTimeout(timer)
    setLoading(true)
    timer = setTimeout(() => {
      API.get(`/persons/search?term=${text}`).then(({ data }) => {
        setPersons(data?.data.items)
        setLoading(false)
        if (!data?.data.items.length) {
          setOrganizationSelected(null)
        }
      })
    }, 1000)
  }

  const handleOrgSearch = (text: string) => {
    if (!text) return
    setNewOrg(text)
    if (text.length < 2) return
    clearTimeout(timer)
    setLoading(true)
    timer = setTimeout(() => {
      API.get(`/organizations/search?term=${text}`).then(({ data }) => {
        setOrganizations(data?.data.items)
        setLoading(false)
      })
    }, 400)
  }


  const handleHunterSelect = (item: any) => {
    LogRocket.identify(item.target.value.id, {
      name: item.target.value.label,
    });
    setHuntersSelected(item.target.value)
  }

  const handleServiceSelect = (item: any) => {
    setServiceSelected(item.target.value)
  }

  const handlePersonSelect = (data = {}) => {
    if (!data) {
      setOrganizationSelected(null)
      setPersonsSelected(null)
      return
    }
    const { item }: any = data
    if (item?.phones.length) {
      item.phone = item.phones[0]
    }
    if (item?.emails.length) {
      item.email = item.emails[0]
    }
    setPersonsSelected(item)
    setOrganizationSelected(item.organization)
  }

  const handleOrganizationSelect = (item: any) => {
    setOrganizationSelected(item)
  }

  const createOrganization = async () => {
    if(!newOrg) {
      setOrganizationError(true)
      new Error("Organization name is required")
      setSnackError(true)
      return false
    }
    return await API.post("/organizations?s=s", { name: newOrg })
  }

  const createPerson = async (orgId: any) => {
    if (!orgId) {
      return new Error("Create person without orgID")
    }
    return await API.post("/persons?s=s", {
      name: newPerson,
      email: newPersonMail,
      phone: newPersonPhone,
      org_id: orgId,
    })
  }

  const createDeal = async (orgId: any, personId: any) => {
    if (!orgId || !personId) {
      return new Error("Create person without orgID")
    }
    let data: any = {
      title: title,
      org_id: orgId,
      person_id: personId,
    }
    data[hunterFieldKey] = hunterSelected.id
    data[serviceFieldKey] = serviceSelected
    data[descriptionFieldKey] = description

    return API.post("/deals?s=s", data).then((res) => {
      setPersons([])
      setOrganizations([])
      setHuntersSelected(null)
      setServiceSelected(null)
      setPersonsSelected(null)
      setOrganizationSelected(null)
      setTitle("")
      setDescription("")
      setNewPerson("")
      setNewPersonPhone("")
      setNewPersonMail("")
      setNewOrg("")
      setLoading(false)
    })
  }

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const invalidForm = () => {
    let isError = false
    if(hunterSelected === null){
      setHunterError(true)
      isError = true
    }
    if(serviceSelected === null){
      setServiceError(true)
      isError = true
    }
    if(newPerson === '' && !personSelected) {
      setPersonError(true)
      isError = true
    }
    if(!newOrg && !organizationSelected) {
      setOrganizationError(true)
      isError = true
    }
    if(newPersonPhone === '' && !personSelected) {
      setPersonPhoneError(true)
      isError = true
    }
    if(!validateEmail(newPersonMail) && !personSelected) {
      setPersonMailError(true)
      isError = true
    }
    if(title === ''){
      setTitleError(true)
      isError = true
    }
    if(description === ''){
      setDescriptionError(true)
      isError = true
    }
    setSnackError(isError)
    return isError
  }

  const handleOpenDeal = async () => {
    setHunterError(false)
    setServiceError(false)
    setTitleError(false)
    setDescriptionError(false)
    setPersonPhoneError(false)
    setPersonError(false)
    setPersonMailError(false)
    setOrganizationError(false)

    if(invalidForm()) return

    setOpen(true)
    try{
      
      let organization_id
      let person_id
  
      if (!organizationSelected) {
        const org = await createOrganization()
        if(!org) return
        const { data } = org
        const newOrgID = data.data.id
        organization_id = newOrgID
      } else {
        organization_id = organizationSelected.id
          ? organizationSelected.id
          : organizationSelected.item.id
      }
      if (personSelected) {
        person_id = personSelected.id
      } else {
        const person: any = await createPerson(organization_id)
        person_id = person.data.data.id
      }
  
      try {
        await createDeal(organization_id, person_id)
      } catch (e) {
        console.error(e)
      }
  
      setSnack(true)
      setTimeout(() => {
        setSnack(false)
        setOpen(false)
        window.location.reload(false)
      }, 2000)
    }catch(e){

    }
    
  }

  return (
    <Container  maxWidth="md">
      <Card>
        <Typography variant="body1" component="span">
          Olá pessoal,
        </Typography>
        <Typography variant="body1" component="span">Por favor escrever cuidadosamente a <b>“Descrição da solicitação"</b> e não se esqueça de informar o ticket do Brasis caso tenha algum chamado de referência! <br/> Assim, nós do comercial seremos mais assertivos e rápidos nesta negociação.<br/> Obrigado!</Typography>
        <br />
        <InputLabel id="hunter-label">Qual é o seu nome?*</InputLabel>
        <Select
          onChange={handleHunterSelect}
          labelId="hunter-label"
          fullWidth
          required
          error={hunterError}
          value={hunterSelected}
          variant="outlined"
          id={testID.input.hunter}
        >
          {hunters &&
            hunters.map((hunter: any) => {
              return <MenuItem value={hunter}>{hunter.label}</MenuItem>
            })}
        </Select>
        <br />
        <InputLabel id="service-label">Qual é o serviço e produto que mais se encaixa para essa solicitação?*</InputLabel>
        <Select
          error={serviceError}
          onChange={handleServiceSelect}
          labelId="service-label"
          fullWidth
          required
          value={serviceSelected}
          variant="outlined"
          id={testID.input.service}
        >
          {services &&
            services.map((service: any) => {
              return <MenuItem value={service.id}>{service.label}</MenuItem>
            })}
        </Select>
        <br />
        <Autocomplete
          id={testID.input.person}
          options={persons}
          getOptionLabel={(option) => option?.item?.name}
          renderOption={(option) => option?.item?.name + (option?.item?.organization ? ( ' (' + option?.item?.organization.name + ')' ) : '')}
          loading={loading}
          loadingText={loadingText}
          onInputChange={(_, newInputValue) => {
            handlePersonSearch(newInputValue)
          }}
          onChange={(e, value) => {
            handlePersonSelect(value)
          }}
          noOptionsText={noOptions}
          freeSolo
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              error={personError}
              required
              label="Para qual pessoa é a proposta?"
              variant="outlined"
            />
          )}
        />
        <br />

        {!personSelected ? (
          <>
            <Autocomplete
              id={testID.input.organization}
              options={organizations}
              loading={loading}
              loadingText={loadingText}
              getOptionLabel={(option) => option?.item?.name}
              onInputChange={(_, newInputValue) => {
                handleOrgSearch(newInputValue)
              }}
              onChange={(e, value) => {
                handleOrganizationSelect(value)
              }}
              noOptionsText={noOptions}
              freeSolo
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={organizationError}
                  label="Qual é o nome da empresa desta pessoa?"
                  variant="outlined"
                />
              )}
            />
            <br />
            <TextField
              id={testID.input.personPhone}
              defaultValue={personSelected?.phone}
              fullWidth
              required
              error={personPhoneError}
              onChange={(e) => setNewPersonPhone(e.target.value)}
              value={newPersonPhone}
              label="Qual é o telefone de contato desta pessoa?"
              variant="outlined"
            />
            <br />
            <TextField
              id={testID.input.personMail}
              defaultValue={personSelected?.email}
              fullWidth
              required
              error={personMailError}
              onChange={(e) => setNewPersonMail(e.target.value)}
              value={newPersonMail}
              label="Qual é o e-mail desta pessoa?"
              variant="outlined"
            />
          </>
        ) : (
          <TextField
            id={testID.input.organization}
            defaultValue={organizationSelected?.name}
            fullWidth
            disabled
            label="Qual é o nome da empresa desta pessoa?"
            variant="outlined"
          />
        )}
        {personSelected && (
          <>
            <br />
            <TextField
              id={testID.input.personPhone}
              defaultValue={personSelected?.phone}
              fullWidth
              disabled
              label="Qual é o telefone de contato desta pessoa?"
              variant="outlined"
            />
            <br />
            <TextField
              id={testID.input.personMail}
              defaultValue={personSelected?.email}
              fullWidth
              disabled
              label="Qual é o e-mail desta pessoa?"
              variant="outlined"
            />
          </>
        )}
        <br />
        <TextField
          id={testID.input.dealTitle}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          error={titleError}
          label="Informe o título da solicitação"
          variant="outlined"
          fullWidth
        />
        <br />
        <TextField
          id={testID.input.dealDescription}
          multiline
          value={description}
          required
          error={descriptionError}
          onChange={(e) => setDescription(e.target.value)}
          label="Informe a descrição da solicitação."
          variant="outlined"
          fullWidth
        />
        <br />
        <Button
          id={testID.buttons.openDeal}
          color="primary"
          variant="contained"
          onClick={handleOpenDeal}
        >
          Abrir Solicitação
        </Button>
      </Card>
      <Snackbar open={snack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Solicitação Aberta com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={snackError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Ops! Todos os campos são obrigatórios
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}
