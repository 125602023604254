export const testID = {
  input: {
    hunter: 'hunter-input',
    service: 'service-input',
    person: 'person-input',
    organization: 'org-input',
    personPhone: 'person-phone-input',
    personMail: 'person-mail-input',
    dealTitle: 'title-input',
    dealDescription: 'description-input'
  },
  buttons: {
    openDeal: 'open-deal-button'
  }
}