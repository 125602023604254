import styled from 'styled-components';

export const Container = styled.div`
  padding: 25px;
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  height: 100%;
  box-shadow: 1px 1px 10px rgba(48, 48, 48, 0.1);
  display: flex;
  flex-direction: column;
`;
