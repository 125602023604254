import React, { useEffect, useState } from "react"

import { API } from "./services/api"

import { Form } from './components/Form'
import { MuiThemeProvider } from "@material-ui/core/styles"
import { createMuiTheme, StylesProvider } from "@material-ui/core/styles"
import { MainApp, Image } from "./components/styles"
import { ThemeProvider } from "styled-components"
import LogRocket from 'logrocket'
import 'fontsource-roboto';

function App() {  
  const [hunters, setHunters] = useState<any>([])
  const [services, setServices] = useState<any>([])

  const handleHunterSearch = () => {
    API.get(
      `/hunters?a=a`
    ).then(({ data }) => {
      setHunters(data?.data.options)
    })
  }

  const handleServiceSearch = () => {
    API.get(
      `/services?a=a`
    ).then(({ data }) => {
      setServices(data?.data.options)
    })
  }


  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'light',
          background: {
            default: '#FFF7E7'
          },
          primary: {
            main: 'rgb(255, 169, 0)',
            dark: 'rgb(255, 182, 38)',
            contrastText: 'white'
          }          
        },
      }),
    [],
  );

  useEffect(()=> {
    if(process.env.NODE_ENV !== 'development'){
      LogRocket.init('omngj1/braga-pipedrive-form')
    }
    handleHunterSearch()
    handleServiceSearch()
  },[])

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <MainApp>
            <Image src="https://bragasolucoes.com.br/wp-content/uploads/2020/12/logo.png"/>
            <Form hunters={hunters} services={services} />
          </MainApp>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default App
