import styled from 'styled-components';
import MUIContainer from '@material-ui/core/Container'

export const Container = styled(MUIContainer)`
  padding-left: 0;
  padding-right: 0;
`;

export const MainApp = styled.div`
  background-color: ${({theme}) => theme.palette.background.default};
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
`

export const Image = styled.img`
  padding-bottom: 50px;
`