import React, { FC } from 'react'
import { Container } from './styles'


export const Card: FC = ({children}) => {

  return (
    <Container>
      {children}
    </Container>
  )

} 