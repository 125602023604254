import axios from 'axios'


const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3333/api' : 'https://pipedrive-proxy-buzato.vercel.app/api'

export const API = axios.create({
  baseURL: BASE_URL
})

API.interceptors.request.use(function (config) {
  // Do something before request is sent
  // config.url = config.url + '&api_token=' + API_KEY
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});